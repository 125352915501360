*{
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}
.footer {
  background-image: url('../../assets/footer.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.footer-map{
  font-size: 18px;
}

.footer_container{
  display: flex;
  justify-content: space-between;
  padding: 110px 110px 0px 110px;
}

.footer_heading{
  color: #ffff;
  font-size: 27px;
  font-weight: 700;
  padding-bottom: 10px;
}

.footer_social1, .footer_icon2{
display: inline-block;
}

.footer_info {
list-style: none;
padding: 0;
margin: 0;
text-align: right;
}

.footer_social {
display: flex;
align-items: center;
justify-content: right;
}


.footer_icon {
width: 35px;  
height: 35px;
margin-left: 10px;
padding-top: 10px;
}

.footer_icon.location,.footer_icon.call,.footer_icon.mail{
margin-top: -10px;
}

.footer_contact ul, .footer_contact{
text-align: right;
list-style-type: none;
}

.footer_info{
  color: #ffff;
  font-size: 18px;
  margin-top: 20px;
}

.footer_social a:hover{
  color: #ffd000;
}

.footer_info li{
  padding: 7px;
}

.footer_heading_underline{
  width: 15rem;
  height: 1.9px;
  margin-left: 230px;
  margin-bottom: 5px;
  background-color: #ffd000;
}


.footer_info a{
  text-decoration: none;
  color: #ffff;
}


.footer_logo img{
  margin: 120px 0 0 50px;
  width: 110px;
  height: 110px;
}
.footer_info {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
}

.footer_social_icons {
  display: flex;
  justify-content: space-evenly; 
  /* text-align: center; */
  /* gap: 20px;  */
  margin-bottom: 15px;
  /* padding-left: 20px; */
  margin-right: 0px;
  margin-left: 60%;
  font-size: 12px;
}
/* .footer_icon_soc{
  font-size: 5px;
} */

.footer_social_icons a {
  /* display: inline-flex; */
}

.footer_social {
  display: flex;
  align-items: center;
  justify-content: right;
}

.footer_icon {
  /* width: 35px;  
  height: 35px; */
  /* margin-left: 10px; */
}

.footer_info li {
  padding: 7px;
}

/* .footer_social_icons a:hover,
.footer_social_icons a:hover {
  color: #ffd000;
} */
.footer_social_icons img[src*="insta_icon"] {
  width: 60px;
  height: 60px;
  
}

.footer_social_icons img[src*="Facebook"] {
  width: 50px;
  height: 60px;
}

@media(max-width:1030px){
  .footer_container{
    padding: 60px 60px 0px 0px ;
  }

  .footer_heading{
    font-size: 26px;
  }
  .footer_info{
    font-size: 14px;
    margin-top: 0px;
  }
  .footer_icon{
    height: 36px;
    width: 36px;
  }
  .footer_heading_underline{
    width: 14.3rem;
  }
  .footer_logo img{
    position: relative;
    margin: 70px 0px 0px 90px;
  }
  .footer_social_icons {
    justify-content: center;
    gap: 8px; 
  }
  .footer_social_icons img {
    width: 50px; 
    height: 55px; 
  }
}

@media(max-width:780px){
  .footer_container{
    padding: 45px 50px 0px 50px;
  }
  .footer_heading{ 
      font-size: 20px;
  }
  .footer_info{
    font-size: 14px;
    margin-top:20px;
  }
  .footer_info li{
    padding: 2px;
  }
  .footer_icon{
    width: 30px;  
    height: 30px;
  }
  .footer_heading_underline{
    width: 12.5rem;
    margin-top: -5px;
    height: 1.6px;
  }
  .footer_logo img{
    margin: 50px 0px 0px 35px;
    height: 80px;
    width: 80px;
  }
  .footer_social_icons {
    justify-content: center; 
    /* gap: 12px; */
    padding-left: 30px;
  }
  .footer_social_icons img {
    width: 30px;
    height: 35px; /* Adjust icon size for small screens */
  }
}

@media(max-width:450px){
  /* .footer_container{
    padding: 45px 50px 0px 50px;
  } */
  .footer{
    /* height: 200px; */
  }
  .footer_logo img{
    display: none;
  }
  .footer_contact{
    padding-top: 18px;
  }
  .footer_heading{
    font-size: 14px;
    padding-right: 5px;
}
  .footer_container{
    padding:20px 10px;
  }
  .footer_info{
    font-size: 12px;
    margin-top:5px;
  }
  .footer_info li{
    padding-top: 1px;
  }
  .footer_icon{
    width: 23px;  
    height: 23px;
    margin: 0px;
    /* padding-left: 20px; */
  }
  .footer_heading_underline{
    width: 7.5rem;
    margin-top: -8px;
    height: 1.1px;
    margin-right: 5px;
  }
  .footer_social span {
    margin-right: -5px;
  }
  .footer_social_icons {
    
      display: flex; 
     /* padding-left: 0px;
     padding-right: 40px; */
     margin-right: 20px;
      gap: 10px; 
      margin-top: 10px; 
    }
  
    
  
  /* .footer_social_icons img {
    width: 10px;
    height: 5px; 
  } */
  .footer_social_icons img[src*="insta_icon"] {
    width: 30px;
    height: 45px;
    
  }
  
  .footer_social_icons img[src*="Facebook"] {
    width: 30px;
    height: 42px;
  }
  .footer-map{
    font-size: 12px;
  }
}