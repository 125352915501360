*{
  margin: 0px;
  padding:0px;
}

/* General Styles */
.teachers-section {
  padding:200px 160px 0px 90px;
  box-sizing: border-box;
}

  
  /* Container for consistent margin */
  .content-container {
    max-width: 1500px;
    /* padding-left: 5px; 
    padding-right: 5px;  */
 
  }
  
  /* Heading Styles */
  .teacher_heading {
    margin-bottom: 20px;
    font-size: 22px;
    /* padding-left: 40px; */
    
  }
  
  /* Teacher Content */
  /* .teacher_content {
    text-align: justify;
    font-size: 20px;
    line-height: 1.5rem;
    color: black;
    margin-bottom: 30px;
  } */
  
  /* Teachers Header */
  .teachers-header {
    margin-bottom: 70px;
  }
  
  /* Highlighted Text */
  .teachers-header .highlight {
    color:#216477;
  }
  
  /* Teacher Content */
  .teacher_contentone {
    /* padding: 15px 5px;  */
    font-size: 22px;
    color: black;
    line-height: 2.5rem;
    text-indent: 25px;
    margin-bottom: 30px;
    margin-right: -65px;
    text-align: justify;
    box-sizing: border-box;
    font-family:'Lato';
    justify-content: justify;
  }
  
  /* Teachers Grid */
  .teachers-grid {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;
    flex-wrap: wrap;
    /* padding-left: 5px;  */
    /* margin-right: -50px;  */
    box-sizing: border-box;
  }
 
  
  

  @media (min-width: 901px) {
    
    .teachers-grid {
      justify-content: space-around;
    }
    .teachers-grid > * {
      flex-basis: 30%;
    }
  }
  
  
  @media (max-width: 900px) and (min-width: 601px) {
    
    .teachers-grid {
      justify-content: center;
    }
    .teachers-grid > * {
      flex-basis: 45%;
    }
    .teachers-grid > *:nth-child(3) {
      margin: 0 auto; 
    }
  }
  @media (max-width: 999px) {
    .teachers-section{
      padding:30px 80px 0px 80px;
    }
    .teacher_contentone{
      font-size: 18px;
      line-height: 1.5rem;
      padding-right: 50px;
    }
    
  }
  
  @media(max-width:768px){
    .teachers-section{
      padding-top: 200px;
    }
  }

  @media (max-width: 500px) {
    .teachers-grid {
      justify-content: center;
      text-align: center;
      padding-right: 70px;
      top:30px;
    }
    .teachers-grid > * {
      flex-basis: 90%;
    }
    .teacher_contentone{
      width :100%;

    }
    .teacher_heading{
      font-size: 18px;
    }
    .teachers-section{
      padding:10px 30px 0px 20px;
    }
    .teacher_contentone{
      font-size: 16px;
      line-height: 1.5rem;
      padding-right: 5px;
    }
  }

  @media(max-width:440px){
    .teachers-section{
      padding-top: 150px;
    }
  }
  
  
  
  /* Responsive Styles */
  /* @media (min-width: 320px) and (max-width: 361px) {
    .teacher_contentone {
      font-size: 16px;
      width: calc(100% - 5px); 
    }
  }  */
  
  /* @media (min-width: 361px) and (max-width: 768px) {
    .teacher_contentone {
      font-size: 18px;
      width: calc(100% - 5px); 
    }
  }
  
  @media (min-width: 768px) {
    .teacher_contentone {
      width: 100%;
      max-width: 1150px;
      font-size: 20px;
    }
  }
  
  @media (min-width: 1200px) {
    .teacher_contentone {
      width: 100%;
      max-width: none;
    }
  }*/
  
    /* @media (max-width: 500px) {
    .teachers-grid {
      flex-direction: column;
      align-items: center;
      width:400px;
    }
  }   */