/* html,
body { */
    /* overflow-x: hidden; */
    /* Prevent scroll on narrow devices */
/* } */

body {

    line-height: 1;
    /* overflow-x: hidden; */
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
