/* body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: consolas;
  } */
  
  .card-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }
  
  .card-container .card {
    position: relative;
    cursor: pointer;
  }
  
  .card-container .card .card-face {
    width: 300px;
    height: 200px;
    transition: 0.5s;
  }
  
  .card-container .card .card-face.card-face1 {
    position: relative;
    background: #033A3D;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
  }
  
  .card-container .card:hover .card-face.card-face1 {
    background: #033A3D;
    transform: translateY(0);
  }
  
  .card-container .card .card-face.card-face1 .card-content {
    opacity: 0.8;
    transition: 0.5s;
  }
  
  .card-container .card:hover .card-face.card-face1 .card-content {
    opacity: 1;
  }
  
  .card-container .card .card-face.card-face1 .card-content img {
    max-width: 100px;
  }
  
  .card-container .card .card-face.card-face1 .card-content h3 {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 1.5em;
  }
  
  .card-container .card .card-face.card-face2 {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(-100px);
  }
  
  .card-container .card:hover .card-face.card-face2 {
    transform: translateY(0);
  }
  
  .card-container .card .card-face.card-face2 .card-content p {
    margin: 0;
    padding: 0;
  }
  
  .card-container .card .card-face.card-face2 .card-content a {
    /* margin: 15px 0 0; */
    display: inline-block;
    text-decoration: none;
    font-weight: 900;
    color: #333;
    padding: 5px;
    border: 1px solid #333;
  }
  
  .card-container .card .card-face.card-face2 .card-content a:hover {
    background: #FFD700;
    color: #000;
  }

  @media(max-width:768px){
    .card-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }
  