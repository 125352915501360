.curri-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  width: 250px; /* Reduced width */
  height: 350px; /* Set a specific height */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-bottom: 30px;
  background-size: cover; /* Ensure the background image covers the card */
}

.curri-card::before {.curri-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  width: 250px; /* Reduced width */
  height: 350px; /* Set a specific height */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-bottom: 30px;
  background-size: cover; /* Ensure the background image covers the card */
  transition: box-shadow 0.3s ease; /* Smooth shadow transition */
}

/* Remove the gradient overlay */
.curri-card::before {
  display: none;
}

.curri-card:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); /* Light shadow on hover */
}

.curri-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(35, 115, 126, 0.7); /* Lighter background */
  z-index: 3; /* Ensure this is above the gradient */
  color: white;
  padding: 40px;
  transition: bottom 0.6s ease-in-out; /* Smooth transition for hover */
}

.curri-card:hover .curri-info {
  bottom: 50%; /* Moves the text to the center on hover */
  transform: translateY(50%); /* Aligns the text perfectly */
}

.curri-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.curri-hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  opacity: 0;

  transition: opacity 0.4s ease-in-out;
}

.curri-card:hover .curri-hover-content {
  opacity: 1; /* Show hover content */
}

.curri-description {
  margin-bottom: 5px; /* Space between description and button */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  font-family: Arial, sans-serif;
}

.know-more-btn {
  background-color: #1a8f98;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.know-more-btn:hover {
  background-color: #126b73;
}
.curri-name {
  padding: 40px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  text-align: center;
}


  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(35, 115, 126, 0.9), rgba(35, 115, 126, 0.3));
  z-index: 2;
  transition: transform 0.6s ease-in-out;
  transform: translateY(100%);
}

.curri-card:hover::before {
  transform: translateY(0);
}

.curri-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(35, 115, 126, 0.9);
  z-index: 3; /* Ensure this is above the gradient */
  color: white;
  padding: 10px;
  transition: bottom 0.6s ease-in-out; /* Smooth transition for hover */
}

.curri-card:hover .curri-info {
  bottom: 50%; /* Moves the text to the center on hover */
  transform: translateY(50%); /* Aligns the text perfectly */
}

.curri-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.curri-hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.curri-card:hover .curri-hover-content {
  opacity: 1; /* Show hover content */
}

.curri-description {
  margin-bottom: 5px; /* Space between description and button */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  font-family: Arial, sans-serif;
}

.know-more-btn {
  background-color: #1a8f98;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.know-more-btn:hover {
  background-color: #126b73;
}
