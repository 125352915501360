.eark__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 0rem 2rem;
    background-color: #ffff;
    width: 100%;
    /* position: relative; Add this if not present */
        z-index: 500;
        height: 15%;
    
}


.eark__navbar-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.eark__navbar-links_logo {
    border-radius: 50%;
    width: 100px;
    /* height: 100px; */
    overflow: hidden;
    box-shadow:6px 6px 5px rgba(0, 0, 0, 0.15) ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    /* position: fixed; */
    z-index: 1000;
}

.eark__navbar-links_logo img {
    
    width: 100%;
    height: auto;
    border-radius: 50%;

   

}

.eark__navbar-links_container {
    display: flex;
    flex-direction: row;
}




.eark__navbar-links_container p a
 {
    text-decoration: none;
    color: black;
    font-family: var(--font-satoshi);

    font-weight: 700px;
    font-size: 20px;
    /* padding: 0; */
    line-height: 27px;
    text-transform: capitalize;

    margin: 0 .5rem;
    cursor: pointer;
}
.eark__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.eark__navbar-menu svg {
    background-color: var(--color-primary2);
    cursor: pointer;

    transform: scale(1.2); 
    border-radius: 8px;
    padding: 3px;
    width: 25px;
    height: 25px;
}

.eark__navbar-menu_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    
    text-align: center;
    /* background:; */
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.eark__navbar-menu_container p {
    margin: 1rem 0;
}

.eark__navbar-links a,
.eark__navbar-menu_container-links a {
  text-decoration: none;
  color: black; 
  padding: 10px 20px;
  display: inline-block;
  transition: color 0.5s, background-color 0.3s; 
  position: relative; 
}

.eark__navbar-links a.active,
.eark__navbar-menu_container-links a.active {
  color: #0BB3BD; 
}


/* Styles for navbar links container (for both desktop and mobile) */
.eark__navbar-links_container {
  display: flex;
  align-items: center;
  /* gap: 10px;  */
}


.eark__navbar-menu_container {
  display: none; 
}


.eark__navbar-menu_container.scale-up-center {
  display: block; 
  z-index: 1000;
  background-color: #ffff;
}

.eark__navbar-menu_container-links p {
  margin: 0;
}

@media  screen and (max-width:970px) {
    .eark__navbar-links_container p a{
        font-size: 16px;
        padding:  1rem 0.5rem;
        gap: 80px; 

    }
    
}

@media screen and (max-width: 760px){
    .eark__navbar-links_logo img{
        height: 100px;
        width: 100px;
    }

    .eark__navbar{
        padding: 32px 32px 0px;
    }
}

@media screen and (max-width: 760px) {

    .eark__navbar-links_logo  img{
        width: 70px;
        height: 70px;

    }
    /* .eark__navbar{
        padding: 2rem;
    } */

    .eark__navbar-links_container {
        display: none;
    }


    .eark__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 550px) {
    .eark__navbar-links{
        margin: -15px 0px -20px -20px;
    }
    .eark__navbar-menu svg {
        width: 25px;
        height: 25px;


    }
    .eark__navbar-links_logo{
        width: 80px;
        height: 80px;
    }

    .eark__navbar-links_logo  img{
        width: 80px;
        height: 80px;
        /* //incoming change */
        /* margin-top: -13px;
        margin-left: -15px; */


    }
    .eark__navbar {
        padding: 1.6rem;
    }

    .eark__navbar-menu_container {
        top: 20px;
    }


}
