

/* .home{
    padding-top:100px;
} */

.home_hero_container{
    background-image: url('../../assets/Desktopheroimg.png');
    background-size: contain; 
    background-repeat: no-repeat;
    height: 134vh;
    background-position: right;
}

.home_scl_name{
    display: flex;
    font-family: 'Albertus Medium', sans-serif;
    width: 551px;
    height: 267px;
    padding-left: 99px;
    padding-top: 9.5rem;
}
.scl_name{
    font-size: 72px;
    font-weight: 900;
    /* line-height: 104%; */
    color: #216477;
    text-shadow: 0px 4px 6.4px rgba(0, 44, 47, 0.7);
}

.home_description{
    width: 425px;
    height: 60px;
    margin: 120px 0px 0px 99px ;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: justify;
    line-height: 30px;
    color: #033A3D;
}
.home_img_btns{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-left: 99.3px;
}

.home_apply_btn{
    width: 403.86px;
    height: 54.94px;
    background-color: #FFD700;
    box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.7), 1px 1px 5px rgba(0, 0, 0, 0.2), 5px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    font-family: 'Satoshi',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 120%;
    color: #033A3D;
    border: none;
    cursor: pointer;
    display: inline-block; /* Ensures the link behaves like a button */
    text-decoration: none; /* Removes underline */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.home_img_btns a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 403.86px;
    height: 54.94px;
    text-decoration: none;
    box-sizing: border-box;
    border: 1px solid #FFD700;
    border-radius: 16px;
    font-family: 'Satoshi',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 120%;
    color: #033A3D;
    background-color: #ffff;
    cursor: pointer;
    margin-top: 50px;
}

.home_broucher_btn{
    width: 403.86px;
    height: 54.94px;
    text-decoration: none;
    box-sizing: border-box;
    border: 1px solid #FFD700;
    border-radius: 16px;
    font-family: 'Satoshi',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 120%;
    color: #033A3D;
    background-color: #ffff;
    cursor: pointer;
    margin-top: 50px;
    display: inline-block; /* Ensures the link behaves like a button */
    text-decoration: none; /* Removes underline */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_content{
    display: flex;
    flex-direction: column;
    margin: -130px 105px 100px 105px;
}

.home_content_heading{
    font-family: 'Satoshi', sans-serif;
    font-weight: 700;
    font-size: 39px;
    line-height: 53px;
    color: #216477;
}

.home_para{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 200px;
    text-align: justify;
    font-weight: 400;
    font-size: 25px;
    line-height: 37px;
    margin-top: 40px;
}

.home_para1{
    width: 48%;
}

.home_para2{
    width: 42%;
}

.home_form_outer{
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
}


.home_form{
    font-family: 'Satoshi', sans-serif;
    width: 773.46px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0px 70px 0px;
}

.home_form_details,.home_form_message{
    padding: 22px;
}

.home_form_details input{
    width: 771.74px;
    height: 48.81px;
    box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.7), 1px 1px 5px rgba(0, 0, 0, 0.1), 5px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: #EAEAEA;
    border: none;
    font-weight: 400;
    font-size: 20px;
    box-sizing: border-box;
    padding-left: 20px;
}

.home_form_message #msg_text{
    width: 771.74px;
    height: 200.55px;
    box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.7), 1px 1px 5px rgba(0, 0, 0, 0.1), 5px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: #EAEAEA;
    border: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    box-sizing: border-box;
    padding: 20px;
}


.home_form_btn button{
    width: 345.43px;
    height: 52.56px;
    border-radius: 10px;
    border: none;
    color: #FFFF;
    background-color: #216477;
    box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.7), 1px 1px 5px rgba(0, 0, 0, 0.1), 5px 5px 10px rgba(0, 0, 0, 0.25);
    font-weight: 500;
    font-size: 31px;
    margin: 40px;
    cursor: pointer;
}

.contact_sec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    /* padding: 100px; */
    /* padding-left: 90px; */
    /* margin-right: 100px; */
    padding-top: 190px; 
    /* background-color: #0BB3BD; */
  }
  .message-box {
    width: 40%;
    padding: 50px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 8px 32px 0  #0BB3BD;
    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .message-box:hover {
    /* transform: scale(1.05); */
    box-shadow: 0 12px 40px  #0BB3BD;
  }
  
  .contact-h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  .form-group {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 8px;
    color:  #0BB3BD;
    font-weight: bold;
  }
  .form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #f9f9f9;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color:  #0BB3BD;
  box-shadow: 0 0 8px rgba(84, 0, 188, 0.2);
  outline: none;
}
.home_form_btn button {
    background-color:  #033A3D; 
    color: white; 
    padding: 10px 20px 10px; 
    border: none; 
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 16px; /* Button text size */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    width: 100%; /* Make button take full width */
  }
  
  .home_form_btn button:hover {
    background-color: #0BB3BD; 
  }
  
  .home_form_btn button:active {
    transform: scale(0.98); /* Slightly shrink button on click */
  }
  
  .home_form_btn button:focus {
    outline: none; /* Remove focus outline */
    box-shadow: 0 0 5px#0BB3BD; /* Add subtle shadow on focus */
  }
  .submit-button {
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    background-color: #033A3D;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0BB3BD;
    transform: translateY(-3px);
  }
@media(max-width:1230px){
    .home_hero_container{
        height: 138vh;
    }
    .scl_name{
        font-size: 68px;
    }
    .home_scl_name{
        padding-top: 135px;
    }
    .home_description{
        margin-top: 130px;
        font-size: 20px;
        width: 350px;
        line-height: 25px;
        text-align: justify;
    }
    .home_apply_btn,.home_broucher_btn{
        /* width: 350px;
        height: 50px; */
        /* width: 100%; */
        font-size: 18px; 
        border-radius: 12px;
    }
    .home_broucher_btn{
        margin-top: 35px;
    }
    .home_img_btns{
        margin-top: 50px;
    }
    .home_para{
        font-size: 22px;
    }
    
}

@media(max-width:1100px){
    .home_hero_container{
        height: 130vh;
    }
    .scl_name{
        font-size: 60px;
        font-weight: 800;
    }
    .home_scl_name{
        padding-top: 125px;
    }
    .home_description{
        margin-top: 70px;
        font-size: 20px;
        width: 300px;
        line-height: 25px;
    }
    .home_img_btns{
        margin-top: 35px;
    }
    .home_apply_btn,.home_broucher_btn{
        /* width: 300px;
        height: 40px; */
        font-size: 18px; 
        border-radius: 12px;
    }
    .home_broucher_btn{
        margin-top: 35px;
    }
    .home_content_heaing{
        font-weight: 600;
        font-size: 28px;
    }
    .home_para{
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        margin-top: 25px;
    }
    .home_form{
        margin: 60px 0px 60px 0px;
    }
    .home_form_details input,.home_form_details #msg_text{
        width: 550px;
        height: 46px;
        font-size: 18px;
    }
    .home_form_message input,.home_form_message #msg_text{
        width: 550px;
        height: 200px;
        font-size: 18px;
    }
    .home_form_btn button{
        width: 220px;
        height: 45px;
        border-radius: 8px;
        font-weight: 400;
        font-size: 26px;
    }
}

@media(max-width:780px){
    .home{
        padding-top: 130px;
    }
    .home_hero_container{
        height: 88vh;
    }
    .scl_name{
        font-size: 46px;
        font-weight: 7800;
    }
    .home_scl_name{
        padding-top: 90px;
        padding-left: 48px;
    }
    .home_description{
        margin-top: -10px;
        font-size: 18px;
        width: 280px;
        line-height: 24px;
        margin-left: 48px;
    }
    .home_img_btns{
        margin-top: 25px;        
        padding-left: 48.37px;
    }
    .home_apply_btn,.home_broucher_btn{
        /* width: 250px;
        height: 35px; */
        font-size: 16px; 
        border-radius: 8px;

    }
    .home_broucher_btn{
        margin-top: 25px;
    }
    .home_content{
        margin: -50px 46.33px 0px 46.33px;
    }
    .home_content_heaing{
        font-weight: 600;
        font-size: 28px;
    }
    .home_para{
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: px;
        margin-top: 15px;
    }
    .home_para1,.home_para2{
        width: 100%;
        text-indent: 65px;
        margin-bottom: 25px;
    }
    .home_form{
        margin: 140px 0px 40px 0px;
    }
    .home_form_details input,.home_form_details #msg_text{
        width: 450px;
        height: 36px;
        font-size: 16px;
    }
    .home_form_message input,.home_form_message #msg_text{
        width: 450px;
        height: 150px;
        font-size: 16px;
    }
    .home_form_details,.home_form_message{
        padding: 16px;
    }
    .home_form_btn button{
        width: 160px;
        height: 35px;
        border-radius: 8px;
        font-weight: 300;
        font-size: 20px;
    }
    .message-box {
        width: 90%;
        padding: 20px;
      }
    
      .contact-h2 {
        font-size: 1.8rem;
      }
    
      .submit-button {
        width: 100%;
      }
}

@media(max-width:440px){
    *{
        margin: 0px;
        padding:0px;
    }
    .home_hero_container{
        background-image: url('../../assets/Mobileheroimg.png');
        margin-top: -80px;
    }
    .scl_name{
        font-size: 46px;
        font-weight: 800;
        text-shadow: 0px 4px 3.8px rgba(0, 44, 47, 0.2);
        /* margin-top: auto; */
        
    }
    .home_scl_name{
        padding-top: 460px;
        padding-left: 35px;
        line-height: 36px;
        width: 199px;
        height: 108px;
    }
    .home_description{
        margin-top: 160px;
        margin-left: 35px;
        font-size: 14px;
        width: 280px;
        line-height: 19px;
        left:30px;
    }
    .home_img_btns{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0px; 
        padding: 0px 25px;
        /* width: 300px; */
        justify-content: space-between;
    }
    
    .home_apply_btn,.home_broucher_btn{
        width: 180px;
        height: 50px;
        font-size: 14px; 
        border-radius: 10px;
        font-size: 16px;
    }
    .home_broucher_btn{
        margin-top: 0px;
    }
    .home_content{
        margin: 65px 30px 0px 30px;
    }
    .home_content_heaing{
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }
    .home_para{
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 5px;
    }
    .home_para1,.home_para2{
        text-indent: 40px;
    }
    .home_form{
        margin-top: 170px;
        margin-bottom: 20px;
    }
    .home_form input,.home_form #msg_text{
        width: 320px;
        font-size: 14px;
    }
    .home_form_details,.home_form_message{
        padding: 10px;
    }
    .home_form_btn button{
        width: 120px;
        height: 30px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        margin-top: 30px;
    }
    .contact_sec {
        padding-top: 300px;
    }
}

@media(max-width:324px){
    .scl_name{
        font-size: 35px;
    }
    .home_description{
        width: 250px;
    }
    .home_apply_btn,.home_broucher_btn{
        width: 130px;
    }
    .home_form{
        margin-top: 300px;
        margin-bottom: 20px;
    }
    .home_form input ,.home_form #msg_text{
        width: 270px;
    }
}