@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

/* General Styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

/* About Section */
.eark__abt_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
}

.eark__abt_card_holder {
 
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 160px 80px;
}

.eark__abt_card_heading h2 {
  display: flex;
  justify-content: center;
  font-size: 25px;
  align-items: center;
  font-weight: 900;
  font-family: var(--font-lato);
  color: var(--color-primary2);
}

.mission_logo,.vision_logo{
  margin-top: -45px;
  margin-left: 45px;
}

.eark__abt_card_sml,
.eark__abt_card_big {
  background-color: #f3f3f3;
  border-radius: 33px;
  box-sizing: border-box;
}

.eark__abt_card_sml {
  height: 380px;
  width: 506px;
}

.eark__abt_card_sml p,
.eark__abt_card_big p {
  text-align: justify;
  text-indent: 40px;
  font-family: var(--font-lato);
  font-size: 20px;
  line-height: 30px;
  margin: 25px 36px 0px 36px;
}

.eark__abt_card_big li {
  text-align: justify;
  font-family: var(--font-lato);
  font-size: 20px;
  line-height: 30px;
  margin: 0 36px;
}

.eark__abt_card_big {
  width: 714px;
  height: 380px;
}

.eark__abt_scal_container {
  position: absolute;
  margin-top: 20px;
  width: 700px;
  height: 1200px;
  z-index: 1;
}

.circle-container {
  position: absolute;
  left: -8rem;
  top: 43.8rem;
  background-color: #216477;
  width: 202px;
  height: 202px;
  border-radius: 50%;
  transition: all 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-container.expanded {
  width: 1040px;
  height: 602px;
  border-radius: 25%;
  left: -9rem;
}

.eark__abt_video-container {
  position: relative;
  top: 18px;
  width: 870px;
  height: 450px;
  display: flex;
  justify-content: right;
  overflow: visible;
}

.eark__abt_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* transition: all 0.1s ease-in-out; */
}

.abt__custom-list {
  list-style-type: none;
}

.abt__custom-list li::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary1);
  border-radius: 50%;
  margin-right: 10px;
}

#abt__card_hgt {
  margin: 0;
  color: var(--color-primary1);
  font-size: 20px;
  font-family: var(--font-lato);
  text-align: justify;
}

.abt__school-head {
  margin-top: 120px;
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: auto;
  font-family: var(--font-satoshi);
  font-size: 30px;
  font-weight: 200;
  line-height: 2.5rem;
  text-align: center;
}

#abt__aut {
  color: #FFD700;
  font-family: var(--font-satoshi);
  font-weight: bolder;
}

/* Gallery Section */
.Gallery_whole_container {
  padding:  0 58px;
  background-color: white;
  margin:60px;
  
}
.card_one{
  background-color: #ff5722;
}

/* .feedback_heading {
  margin-bottom: 20px;
  padding-top: 20px;
  margin:10px;
} */

.card_two, .card_one, .card_three, 
.card_four, .card_eight, .card_five, 
.card_six,.card_seven {
  position: relative;
  background-color: white;
  border-radius: 30px;
  box-shadow : 1.9px 1.9px 2.2px rgba(0, 0, 0, 0.07),
  4.7px 4.7px 5.3px rgba(0, 0, 0, 0.05), 8.8px 8.8px 10px rgba(0, 0, 0, 0.042),
  15.6px 15.6px 17.9px rgba(0, 0, 0, 0.035),
  29.2px 29.2px 33.4px rgba(0, 0, 0, 0.028),
  70px 70px 80px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}

.Gallery_top_image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Gallery_image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.card_two:hover .Gallery_image,
.card_one:hover .Gallery_image,
.card_three:hover .Gallery_image,
.card_four:hover .Gallery_image,
.card_eight:hover .Gallery_image,
.card_five:hover .Gallery_image,
.card_six:hover .Gallery_image,
.card_seven:hover .Gallery_image
{
  transform: scale(1.1); /* Zoom the image by 10% */
}
.Gallery_heading {
  text-align: center; /* Center the heading container */
  margin-bottom: 50px;
}

.Gallery_heading h1 {
  color: #216477; /* Similar text color */
  font-family: 'Satoshi'; /* Use the Satoshi font */
  font-weight: 700; /* Bold weight for heading */
  font-size: 45px;
  margin-bottom: 20px; /* Space between heading and underline */
  position: relative;
  display: inline-block;
}

.Gallery_heading h1::after {
  content: '';
  position: absolute;
  left: 50%; /* Start at the center */
  transform: translateX(-50%); /* Adjust to center the underline */
  bottom: -10px; /* Adjust this value to move the line closer or further from the text */
  height: 5px; /* Thickness of the underline */
  background-color: #FFD700; /* Color of the underline */
  width: 100%; /* Adjust the width of the underline */
}



/* Grid layout */
.Gallery_grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(13, 1fr);
 
  /* grid-template-rows: repeat(4, 1fr); */
}

.card_two{
  grid-column: span 3;
  grid-row: span 24;
}
.card_one{
  grid-column: span 4;
  grid-row: span 24;
}

.card_three{
  grid-column: span 3;
  grid-row: span 20;
}

.card_four{
  grid-column: span 3;
  grid-row: span 25;
}

.card_eight{
  grid-column: span 3;
  grid-row: span 30;
}

.card_six{
  grid-column: span 4;
  grid-row: span 26;
}

.card_five{
  grid-column: span 3;
  grid-row: span 24;
}

.card_seven{
 grid-column: span 3;
  grid-row: span 25;
}



@media screen and (max-width: 1024px){
  .eark__abt_video-container{
    width: 755px;
    height: 415px;
  }
  .circle-container.expanded{
    width: 800px;
  height: 560px;
  border-radius: 25%;
  margin-left: -6rem;
  }
  .eark__abt_card_holder {
    gap: 25px;
  }
  .eark__abt_card_sml{
    width: 400px;
    height: 370px;
  }
  .eark__abt_card_big{
    width: 500px;
    height: 370px;
  }
  .eark__abt_card_sml p,.eark__abt_card_big p {
  text-indent: 40px;
  font-size: 18px;
  line-height: 30px;
}

.eark__abt_card_big li {
  text-align: justify;
  font-family: var(--font-lato);
  font-size: 18px;
  line-height: 30px;
  margin: 0 36px;
}
.card_eight#abt__card_hgt{
  font-size: 18px;
}

}


/* Responsive Design */
@media screen and (max-width: 768px) {
  .eark__abt_card_holder {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .eark__abt_card_sml{
    margin: 0 300px 60px 0;
    width: 400px;
    height: 300px;
  }
  .eark__abt_card_big{
    margin-left: 195px;
    width: 500px;
    height: 370px;
  }
  .eark__abt_card_sml p,.eark__abt_card_big p {
  text-indent: 40px;
  font-size: 16px;
  line-height: 25px;
}

.eark__abt_card_big li {
  text-align: justify;
  font-family: var(--font-lato);
  font-size: 16px;
  line-height: 30px;
  margin: 0 36px;
}
#abt__card_hgt{
  font-size: 16px;
}

.circle-container {
  position: absolute;
  top: 68.5rem;
  background-color: var(--color-primary1);
  border-radius: 50%;
  transition: all 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-container.expanded {
  width: 500px;
  height: 402px;
  border-radius: 25%;
  margin-left: 2.8rem;
}

.eark__abt_video-container {
  position: relative;
  width: 870px;
  height: 450px;
  display: flex;
  justify-content: right;
  overflow: visible;
  margin-right:300px;
}

.eark__abt_video {
  width: 60%;
  height: 65%;
  object-fit: cover;
  /* transition: all 0.1s ease-in-out; */
}
.abt__school-head{
  margin-top: 0px;
  padding: 30px 0px;
  font-size: 22px;
  width: 400px;
  line-height: 35px;
}

}

@media screen and (max-width: 440px) {
  .eark__abt_main_container{
    padding-top: 130px;
  }
  .mission_logo,.vision_logo{
    margin-top: -45px;
    margin-left: 30px;
    height: 80px;
  }
  .eark__abt_card_holder {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
  .eark__abt_card_sml{
    margin: 0px;
    margin-top: -90px;
    width: 340px;
    height: 250px;
  }
  .eark__abt_card_big{
    margin: 0px;
    width: 340px;
    height: 380px;
  }
  .eark__abt_card_sml p,.eark__abt_card_big p {
  text-indent: 40px;
  font-size: 16px;
  line-height: 22px;
  margin: 20px 28px 0px;
}

.eark__abt_card_big li {
  text-align: justify;
  font-family: var(--font-lato);
  font-size: 16px;
  line-height: 23px;
  margin: 5px 28px 0px;
}
#abt__card_hgt{
  font-size: 14px;
}
.eark__abt_card_heading h2{
  font-size: 22px;
}
  .circle-container {
    top: 59.8rem;
    border-radius: 50%;
  }
  
  .circle-container.expanded {
    width: 300px;
    height: 260px;
    margin-top: -10px;
    border-radius: 25%;
    left: -8rem;
  }
  
  .eark__abt_video-container {
    width: 550px;
    height: 295px;
    top: -70px;
    margin-right:170px;
  }
  
  .eark__abt_video {
    width: 60%;
    height: 65%;
    object-fit: cover;
    /* transition: all 0.1s ease-in-out; */
  }
  .abt__school-head{
    margin-top: -95px;
    padding: 0px 0px;
    font-size: 18px;
    width: 280px;
    line-height: 25px;
  }
  #abt__aut{
    padding-top: 10px;
  }
}

/* Gallery Section Responsive */
@media (max-width: 800px) {
  .Gallery_grid {
    display: flex;
    flex-direction: column;
   
    gap: 20px;
}

.card_two, .card_one, .card_three, 
.card_four, .card_eight, .card_six, 
.card_five, .card_seven{
    grid-column: span 9;
    grid-row: unset;
    width: 100%;
}
}

@media (min-width: 320px) and (max-width: 500px) {
  .Gallery_whole_container {
    padding: 0;
  }

  .card_one {
    grid-column: span 6;
    grid-row: span 17;
  }

  .card_two {
    grid-column: span 6;
    grid-row: span 20;
  }

  .card_three, .card_four, 
  .card_five, .card_six, 
  .card_seven, .card_eight {
    grid-column: span 6;
  }
}

@media (min-width: 501px) and (max-width: 800px) {
  .Gallery_grid {
    display: flex;
    flex-direction: column;
   
    gap: 20px;
}

.card_two, .card_one, .card_three, 
.card_four, .card_eight, .card_six, 
.card_five, .card_seven{
    grid-column: span 9;
    grid-row: unset;
    width: 100%;
}


}
@media (max-width: 500px) {
  .Gallery_whole_container {
    padding: 0;
}

.Gallery_grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Ensures the grid takes up full width */
}

.card_two, .card_one, .card_three, 
.card_four, .card_eight, .card_five, 
.card_six, .card_seven {
    width: 100%; 
    height: 250px;
    border-radius: 20px; /* Optional to maintain card style */
    grid-row: unset; /* Remove grid-row for better flexibility in flex layout */
}

}

@media (min-width: 501px) and (max-width: 800px) {
/* .card_two,
.card_one,
.card_three,
.card_four,
.card_eight,
.card_six,
.card_five,
.card_seven {
    width: 400px;
    height: 350px; 
} */
.Gallery_grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(10, 1fr);
   
}
 .card_one{
    grid-column: span 5;
grid-row: span 15;
 }
.card_two{
grid-column: span 5;
grid-row: span 17;
}
.card_three{
grid-column: span 5;
grid-row: span 18;
}
.card_four{
grid-column: span 5;
grid-row: span 17;
}
.card_eight{
grid-column: span 5;
grid-row: span 24;
}
.card_five{
grid-column: span 5;
grid-row: span 20;
}
.card_six{
grid-column: span 5;
grid-row: span 24;
}
.card_seven{
grid-column: span 5;
grid-row: span 20;
}
.Gallery_heading h1{
font-size: 35px;
}
}
