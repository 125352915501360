.ad__main-container{
    padding: 10px;
    
   
}

.ad__main-heading {
    text-align: center;

    /* margin-top: 10%; */
    

} 
.ad__head{
    margin-top: 12% ;
    color: #216477; /* Similar text color */
    font-family: 'Satoshi'; /* Use the Satoshi font */
    font-weight: 700; /* Bold weight for heading */
    font-size: 45px;
    margin-bottom: 50px; /* Space between heading and underline */
    position: relative;
    display: inline-block;
}
.ad__head::after{
        content: '';
        position: absolute;
        left: 50%; /* Start at the center */
        transform: translateX(-50%); /* Adjust to center the underline */
        bottom: -10px; /* Adjust this value to move the line closer or further from the text */
        height: 5px; /* Thickness of the underline */
        background-color: #FFD700; /* Color of the underline */
        width: 100%; /* Adjust the width of the underline */
      
}

.ad__head-cont{

    font-family: lato,sans-serif;
    line-height: 1.5rem;
    font-size: 20px;
    padding: 3px;
}
.ad__course-cont{
display: flex;
align-items: center;
justify-content: center;
margin-top: 18px;
gap: 15px;

}
.ad__course-off{
    text-align: left;
    font-size: 18px;
    font-family: lato,sans-serif;
}
.ad__course-off::before{
 content: '✎'; /* Unicode for bullet point */
padding-right: 5px;
  color: black; /* Bullet color */
  font-size: 20px; /* Size of the bullet */
  line-height: 1; /* Aligns bullet with text */
}

.ad__list_inst  {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
  text-align: justify;
    margin-top: 40px;

}
.ad__inst{
    padding: 10px ;
    background-color: #f3f3f3;

    border: #216477 1px solid;
    margin: 18px;
    border-radius: 22px;
    text-align: justify;
    font-size: 18px;
    font-family: lato,sans-serif;
    line-height: 2rem;   
}

.ad__btn_holder{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ad__btn{
    padding: 10px 55px;
    margin: 15px;
    background-color: #FFD700;
    box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.7), 1px 1px 5px rgba(0, 0, 0, 0.2), 5px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    font-family: 'Satoshi',sans-serif;
    color: #033A3D;
    border: none;
    cursor: pointer;
    font-size: 22px;

    
}
.ad__footer{
    margin: 40px 0px 50px;
    line-height: 1.5rem;
}
.ad__no-id{
    padding: 8px 14px ;
    color: #FFD700;
    margin-right: 8px;
    background-color: #216477;
    border: #033A3D solid 1px;
    border-radius: 50%;

}

@media screen and (max-width: 768px) {
    .ad__head{
        margin-top: 140px;
        font-size: 28px;
    }
    .ad__head-cont{
        font-size: 16px;


    }
    .ad__course-off{
        text-align: left;
        font-size: 12px;
        padding-top: 0px;
        padding-right: 0px;
        font-family: lato,sans-serif;
        margin-left: 10px;
    }
    .ad__course-cont{
        gap: 10px;
        font-size: 16px;
        margin-top: 0px;
    }
    .ad__list_inst{
        margin-top: 20px;
    }
    .ad__inst{
        font-size: 16px;
    }
    .ad__btn{
        font-size: 18px;
    }
    .ad__footer{
        padding: 0px 3px;
        font-size: 14px;
    }
}