.teacher-card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    width: 400px; /* Reduced width */
    height: 494px; /* Set a specific height */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 30px;
    /* background: url('../../assets/employerblacktwo.webp') no-repeat center center; */
    background-size: cover; /* Ensure the background image covers the card */
  }
  
  .teacher-card::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(35, 115, 126, 0.9), rgba(35, 115, 126, 0.3)); /* Gradient from bottom to top */
    z-index: 2;
    transition: transform 0.6s ease-in-out;
    transform: translateY(100%);
  }
  
  .teacher-card:hover::before {
    transform: translateY(0);
  }
  
  .teacher-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(35, 115, 126, 0.9);
    z-index: 3; /* Ensure this is above the gradient */
    transition: opacity 0.4s ease-in-out;
    opacity: 1; /* Ensure it's visible by default */
    padding: 10px;
  }

  
  
  .teacher-card:hover .teacher-info {
    opacity: 0; /* Hide the name on hover */
  }
  
  .teacher-hover-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    color: white;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .teacher-card:hover .teacher-hover-content {
    opacity: 1;
  }
  
  .teacher-name {
    padding: 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    text-align: center;
  }
  
  .teacher-description {
    margin-bottom: 10px; /* Space between description and button */
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .know-more-btn {
    background-color: #1a8f98;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
  }
  
  .know-more-btn:hover {
    background-color: #126b73;
  }
  
  .teacher-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the card while maintaining its aspect ratio */
    z-index: 1;
  }
  @media (min-width: 1301px) {
    .teacher-card {
      width: 400px;
      height: 500px;
    }
  }
  
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-card {
      width: 400px;
      height: 500px;
    }
  }
  
  @media (min-width: 501px) and (max-width: 999px) {
    .teachers-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      gap: 20px; 
    }
  
    .teacher-card {
      width: 100%; 
      height: 500px; 
    }
  }
  
  @media (min-width: 320px) and (max-width: 500px) {
    .teacher-card {
      width: 300px;
      height: 400px;
    }
  }
  
  @media (max-width: 350px) {
    .teacher-card {
      width: 300px;
    }
  }
  